import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    meta: { requiresAuth: true }, // Menandakan bahwa rute ini memerlukan autentikasi
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/aktifitas-survey1",
        name: "aktifitas-survey1",
        component: () => import("./pages/aktifitas/Survey1.vue"),
      },
      {
        path: "/aktifitas-survey2",
        name: "aktifitas-survey2",
        component: () => import("./pages/aktifitas/Survey2.vue"),
      },
      {
        path: "/aktifitas-survey3",
        name: "aktifitas-survey3",
        component: () => import("./pages/aktifitas/Survey3.vue"),
      },
      {
        path: "/aktifitas-survey4",
        name: "aktifitas-survey4",
        component: () => import("./pages/aktifitas/Survey4.vue"),
      },
      {
        path: "/aktifitas-survey5",
        name: "aktifitas-survey5",
        component: () => import("./pages/aktifitas/Survey5.vue"),
      },
      // {
      //   path: "/report-survey",
      //   name: "report-survey",
      //   component: () => import("./pages/report/Survey.vue"),
      // },
      // =========================================================
      // VERSI BARU
      // =========================================================
      {
        path: "/aktifitas-survey",
        name: "aktifitas-survey",
        component: () => import("./pages/new/aktifitas/Index.vue"),
      },
      // =========================================================
      {
        path: "/master-agent",
        name: "master-agent",
        component: () => import("./pages/master/Agent.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  // {
  //   path: "/survey1/:id",
  //   name: "survey1",
  //   component: () => import("./pages/survey/Survey1.vue"),
  // },
  // {
  //   path: "/survey2/:id",
  //   name: "survey2",
  //   component: () => import("./pages/survey/Survey2.vue"),
  // },
  // {
  //   path: "/survey3/:id",
  //   name: "survey3",
  //   component: () => import("./pages/survey/Survey3.vue"),
  // },
  // {
  //   path: "/survey4/:id",
  //   name: "survey4",
  //   component: () => import("./pages/survey/Survey4.vue"),
  // },
  // {
  //   path: "/survey5/:id",
  //   name: "survey5",
  //   component: () => import("./pages/survey/Survey5.vue"),
  // },
  {
    path: "/reward-survey1/:id",
    name: "reward-survey1",
    component: () => import("./pages/reward/Survey1.vue"),
  },
  {
    path: "/reward-survey2/:id",
    name: "reward-survey2",
    component: () => import("./pages/reward/Survey2.vue"),
  },
  {
    path: "/reward-survey3/:id",
    name: "reward-survey3",
    component: () => import("./pages/reward/Survey3.vue"),
  },
  {
    path: "/reward-survey4/:id",
    name: "reward-survey4",
    component: () => import("./pages/reward/Survey4.vue"),
  },
  {
    path: "/reward-survey5/:id",
    name: "reward-survey5",
    component: () => import("./pages/reward/Survey5.vue"),
  },
  // =========================================================
  // VERSI BARU
  // =========================================================
  {
    path: "/konfirmasi-hadiah/:survey/:id",
    name: "konfirmasi-hadiah",
    component: () => import("./pages/new/konfirmasi-hadiah/Index.vue"),
  },
  {
    path: "/survey1/:id",
    name: "survey1",
    component: () => import("./pages/new/survey/Survey1.vue"),
  },
  {
    path: "/survey2/:id",
    name: "survey2",
    component: () => import("./pages/new/survey/Survey2.vue"),
  },
  {
    path: "/survey3/:id",
    name: "survey3",
    component: () => import("./pages/new/survey/Survey3.vue"),
  },
  {
    path: "/survey4/:id",
    name: "survey4",
    component: () => import("./pages/new/survey/Survey4.vue"),
  },
  {
    path: "/survey5/:id",
    name: "survey5",
    component: () => import("./pages/new/survey/Survey5.vue"),
  },
  {
    path: "/survey/:survey/:id/:tahapan",
    name: "tahap-survey",
    component: () => import("./pages/new/survey/tahapan/Index.vue"),
  },
  {
    path: "/terimakasih",
    name: "terimakasih",
    component: () => import("./pages/new/Terimakasih.vue"),
  },
  // =========================================================
  {
    path: "/syarat-dan-ketentuan",
    name: "syarat-dan-ketentuan",
    component: () => import("./pages/SyaratKetentuan.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Versi biasa
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("authToken"); // Misalnya, fungsi untuk memeriksa autentikasi pengguna

  if (to.name === "login" && isAuthenticated) {
    next({ name: "dashboard" });
  } else {
    // Mengecek apakah rute saat ini memiliki children
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      if (isAuthenticated) {
        next();
      } else {
        // Jika pengguna belum login, redirect ke halaman login
        next({ name: "login" });
      }
    } else {
      // Jika rute tidak memiliki children, lanjutkan ke rute yang diminta
      next();
    }
  }
});

// Versi permission
// import store from "./store";
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem("authToken"); // Misalnya, fungsi untuk memeriksa autentikasi pengguna
//   const pageName = to.name;

//   if (to.name === "login" && isAuthenticated) {
//     next({ name: "dashboard" });
//   } else {
//     // Mengecek apakah rute saat ini memiliki children
//     if (to.matched.some((route) => route.meta.requiresAuth)) {
//       // Jika pengguna telah login, lanjutkan ke rute yang diminta
//       if (isAuthenticated) {
//         store.dispatch("auth/checkPermission", pageName);
//         setTimeout(() => {
//           if (localStorage.getItem("isPermission") == "false") {
//             next({ name: "access" });
//           } else {
//             next();
//           }
//         }, 250);
//       } else {
//         // Jika pengguna belum login, redirect ke halaman login
//         next({ name: "login" });
//       }
//     } else {
//       // Jika rute tidak memiliki children, lanjutkan ke rute yang diminta
//       next();
//     }
//   }
// });
export default router;
